<template>
	<div>
		<ul class="addasso-additem">
			<li>
				<p class="textleft">请输入原密码</p>
				<el-input
					class="addasso-additem-input"
					placeholder="原密码"
					show-password
					v-model="inputOriginal"
				/>
			</li>
			<li>
				<p class="textleft">请输入新密码</p>
				<el-input
					class="addasso-additem-input"
					placeholder="新密码"
					show-password
					v-model="inputNew1"
				/>
			</li>
			<li>
				<p class="textleft">请再次输入新密码</p>
				<el-input
					class="addasso-additem-input"
					placeholder="重复输入"
					show-password
					v-model="inputNew2"
				/>
			</li>
			<li>
				<el-button
					style="margin-top: 0.5rem; width: 10rem"
					type="primary"
					@click="submit()"
					>提交</el-button
				>
			</li>
		</ul>
	</div>
</template>

<style scoped>
.addasso-additem {
	list-style-type: none;
	display: block;
	width: 40rem;
	margin: auto;
}
.addasso-additem >>> li {
	margin-bottom: 5px;
}
.addasso-additem .textleft {
	text-align: left;
	position: relative;
	padding-left: 8px;
	border-left: 4px solid #1fcab3;
}
.addasso-additem-input {
	width: 100%;
}
li .addbtn {
	margin: 0.7rem 5%;
	width: 35%;
}
</style>

<script>
import axios from "axios";
import qs from "qs";
export default {
	name: "ChangePassword",
	data() {
		return {
			inputOriginal: "",
			inputNew1: "",
			inputNew2: "",
			userid: "",
			MIN_PASSWORD_LENGTH: 6,
			MAX_PASSWORD_LENGTH: 30,
		};
	},
	beforeMount() {
		if (localStorage.getItem("token")) {
			axios.defaults.headers.common["Authorization"] = localStorage.getItem(
				"token"
			);
			axios({
				url: "/api/hello-user",
				method: "GET",
			}).then((res) => {
				this.userid = res.data.userid;
			});
		}
	},
	methods: {
		messageBox(msg, type) {
			this.$message[type](msg);
		},
		submit() {
			if (!this.inputOriginal) {
				this.messageBox("请输入原密码", "error");
				return false;
			}
			if (!this.inputNew1) {
				this.messageBox("请输入新密码", "error");
				return false;
			}
			if (this.inputNew1 !== this.inputNew2) {
				this.messageBox("两次输入的密码不一致", "error");
				return false;
			}
			if (this.inputOriginal === this.inputNew1) {
				this.messageBox("新密码不能与旧密码相同", "error");
				return false;
			}
			if (
				this.inputNew1.length < this.MIN_PASSWORD_LENGTH ||
				this.inputNew1.length > this.MAX_PASSWORD_LENGTH
			) {
				this.messageBox("请输入有效的密码，长度在6-30之间", "error");
				return;
			}
			this.$confirm("此操作将修改您的登录密码, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					axios({
						url: "/api/user/passwd",
						method: "POST",
						headers: {
							"content-type": "application/x-www-form-urlencoded",
						},
						data: qs.stringify({
							userid: this.userid,
							oldpassword: this.inputOriginal,
							newpassword: this.inputNew2,
						}),
					})
						.then(() => {
							// console.log(res);
							this.messageBox("修改成功，请重新登录", "success");
							localStorage.removeItem("token");
							this.$router.push("/");
						})
						.catch((err) => {
							this.messageBox(
								"修改密码失败， " + err?.response?.data?.Message ?? "网络断开或神秘错误",
								"error"
							);
							this.inputOriginal = "";
							console.log("Change Password Failed");
							console.log(err.response);
						});
				})
				.catch(() => {});
		},
	},
};
</script>